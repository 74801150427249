import React, {Component} from 'react';
import {Cascader} from 'antd';

interface ISDKPickerProps {
  onChange: (values: any) => void
}

class SDKPicker extends Component<ISDKPickerProps, any> {

  private options = [
    {
      value: '0',
      label: 'Beta',
      children: [
        {
          value: '0',
          label: '0',
          children: [
            {
              value: '1',
              label: '1'
            }
          ]
        }
      ]
    }
  ];

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  private onChange(value: string[], selectedOptions): void {
    let sdk_major_version = null;
    let sdk_minor_version = null;
    let sdk_patch_version = null;
    if (value != null && value.length > 2) {
      sdk_major_version = value[0];
      sdk_minor_version = value[1];
      sdk_patch_version = value[2];
    }
    this.props.onChange({
      sdk_major_version: sdk_major_version,
      sdk_minor_version: sdk_minor_version,
      sdk_patch_version: sdk_patch_version
    });
  }

  public render(): React.ReactElement {
    return (
      <Cascader
        options={this.options}
        onChange={this.onChange}
        style={{width: '100%'}}
      />
    );
  }
}

export default SDKPicker;